"use client";
import fetchJson from "@/lib/fetchJson";
import useSWR from "swr";
import { useEffect, useState } from "react";
import "blaze-slider/dist/blaze.css";
import Loader from "@/components/CustomUiComponents/Loader";
import "swiper/css";
import { Box, Flex, Heading, Switch, Text } from "@radix-ui/themes";
import { logoUrl } from "@/lib/util";
import { useRouter } from "next/router";
import useUser from "@/lib/useUser";
import CampaignSearch from "./CampaignSearch";
import MobileDashboardView from "./DashboardView/MobileDashboardView";
import { IconUserCircle } from "@tabler/icons-react";
import MobileAdvancedView from "./AdvancedView/MobileAdvanced";
import Image from "next/image";


interface MobileDashboardProps {
  id: string;
  setId: (id: string) => void;
  home?: boolean;
}

const MobileCampaignHeader= ({ id, setId, home }: MobileDashboardProps) => {
  const router = useRouter();
  const { data, mutate } = useSWR<Campaign>(`/campaign/${id}`, fetchJson);
  const { user, checkRoles } = useUser();
  const [logo, setLogo] = useState("");
  const isAdmin = checkRoles(["agency-admin", "superadmin"])
  const d =
  checkRoles(["agency-admin", "agency-user"]) &&
  user.agencyId == data?.agencyId;
  const editable = checkRoles(["superadmin"]) || d;
  const shareable =
    checkRoles(["superadmin"]) ||
    (checkRoles(["agency-admin"]) && data?.agencyId == user.agencyId) ||
    (checkRoles(["agency-user"]) && data?.agencyId == user.agencyId) ||
    checkRoles(["campaign-viewer"]);
  const transferable = checkRoles(["superadmin"])
  let reportable =
    d ||
    (user?.viewableCampaigns?.includes(parseInt(id)) &&
      checkRoles(["campaign-viewer"]));
  if (user?.email === "nick@pulsemusicgroup.com") reportable = false;

  const findView = (home) => {
    if (home) {
      return "dashboard"
    } else {
      return user?.campaignView
    }
  } 
  const [selectedView, setSelectedView] = useState(findView(home))

  useEffect(() => {
    setLogo(window.location.host);
  }, [])
  if (!data) return <Loader full={true} />;
  return (
    <>
      <Flex id="mobile-searchbar-header" direction="row" justify="between" p="3">
        <div className="w-12 h-10">
      <Image   
            src={logoUrl(logo)}
            alt="Logo"
            width={50}
            height={50}
      />
      </div>
      <CampaignSearch/>
      </Flex>
        <Flex id="mobile-campaign-header" gap="3" direction="row" p="5">
          <Box id="mobile-campaign-header-image">
            {data?.song?.image && (
              <Image 
              width={80} 
              height={80}
              className="w-[80px] h-[80px] rounded-2xl"
              alt="artist"
                src={`https://wvemedia.com/image-upload/${data.song?.image || data.artist?.image}`}
                  
              />
              )}
            </Box>
            <Flex id="dashboard-campaign-header-text" direction="column" justify="center" py="1" gap="2">
              <Box id="dashboard-campaign-name">{data && <Heading size="6">{data?.name}</Heading>}</Box>
                <Flex id="dashboard-campaign-subtitle" direction="row" gap="4" align="center">
                    {data && <Flex id="dashboard-artist-name" direction="row" gap="1" align="center">
                        <IconUserCircle className="icon-dark"/>
                        <Text size="2">{data?.artist?.name}</Text> 
                    </Flex>}
                    <Box id="dashboard-campaign-status">
                        {data && (data?.status === 'active' ? 
                        <Flex direction="row" align="center">
                            <div className="status-dot-active"></div> 
                            <Text size="2">Active</Text>
                        </Flex> : 
                        <Flex direction="row" align="center">
                            <div className="status-dot-inactive"></div>
                            <Text size="2">Inactive</Text>
                        </Flex>)}
                      </Box>
                </Flex>
         </Flex>
      </Flex>
      {isAdmin && <Flex direction="row" gap="2" align="center" px="5">
                <Switch checked={selectedView == "advanced"} onCheckedChange={() => {
                  setSelectedView(selectedView === "dashboard" ? "advanced" : "dashboard");
                }} /> 
                <Flex direction="column">
                  <Text size="2">Advanced View</Text>
                </Flex>
              </Flex>}
    {selectedView === "dashboard" ? <MobileDashboardView  id={id} setId={setId}/> : 
    <MobileAdvancedView
    editable={editable} 
    shareable={shareable} 
    reportable={reportable} 
    data={data} 
    mutate={mutate} 
    transferable={transferable}
    />}
    </>
  );
};

export default MobileCampaignHeader
