import { useState } from "react";
import PostsRow from "./PostsRow";
import { Button, Flex } from "@radix-ui/themes";
import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import MobilePostsRow from "./MobilePostsRow";

interface Props {
  fullPosts: any;
}

const MobilePostsGridView = ({
  fullPosts,
}: Props) => {
  const [firstRow, setFirstRow] = useState<any>([0, 2])
  const posts = fullPosts.sort((a: any, b: any) => b.views - a.views).filter((post: any) => !post.missing && (post.thumbnail || post.thumbnailSmall));
  console.log({firstRow})
  return (
    <Flex direction="column" gap="4">
      <MobilePostsRow fullPosts={posts} postsRange={firstRow}/>
      <MobilePostsRow fullPosts={posts} postsRange={[firstRow[0] + 2, firstRow[1] + 2]}/>
      <Flex direction="row" gap="3" justify="center">
        <Button variant="ghost" color="gray" disabled={firstRow[0] === 0} onClick={() => setFirstRow((prev: any) => [Math.max(0, prev[0] - 4), Math.max(2, prev[1] - 4)]) }><ChevronLeftIcon/>Previous</Button>
        <Button variant="ghost" color="gray" disabled={firstRow[0] >= posts.length - 4} onClick={() => setFirstRow((prev: any) => [Math.min((Math.ceil(posts.length / 4) * 4) - 4, prev[0] + 4), Math.min(Math.ceil(posts.length / 4) * 4, prev[1] + 4)]) }>Next<ChevronRightIcon/></Button>
      </Flex>
    </Flex>
  )}

export default MobilePostsGridView;
