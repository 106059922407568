export type ChartItem = "followers" | "ugc" | "streaming" | "adds";
export type ChartValues = Record<ChartItem, number[]>;

export const chartValues = (artist: any, spotify: any, ugc: any) => {
  const artistSpotify = JSON.parse(artist?.followers || "{}");
  const followers = artistSpotify?.followers?.current_period_timeseries?.map(
    (item: any) => item.y,
  );
  const adds = spotify?.playlist_adds?.current_period_timeseries?.map(
    (item: any) => item.y,
  );

  const streaming = artistSpotify?.followers?.current_period_timeseries?.map(
    (item: any) => item.y,
  );
  return {
    followers,
    ugc,
    streaming,
    adds,
  };
};

export const biggestIncrease = (values: ChartValues) => {
  const increase = (values: number[]) => {
    console.log("values", values);
    if (!values || values.length === 0) return 0;
    console.log("values", values[0], values[values.length - 1]);
    const increase = values[values.length - 1] / values[0] - 1;
    return increase;
  };
  const increases: any = {
    followers: increase(values.followers),
    ugc: increase(values.ugc),
    streaming: increase(values.streaming),
    adds: increase(values.adds),
  };
  console.log("increases", increases);
  const max = Object.keys(increases).reduce(function (a: any, b: any) {
    return increases[a] > increases[b] ? a : b;
  });
  return { name: max, val: increases[max] };
};
