import NewEditPost from "@/components/Modals/NewEditPost"
import { formatNumber } from "@/lib/util"
import { deletePost } from "@/services/post"
import { Button, Flex, IconButton, Text } from "@radix-ui/themes"
import { IconAlertTriangle, IconChevronDown, IconHeart, IconMessage, IconPlayerPlay, IconTrash } from "@tabler/icons-react"
import Image from "next/image"
import Link from "next/link"
import { useState } from "react"
import { KeyedMutator } from "swr"

interface MobilePostListRowProps {
    post: any;
    editable: boolean;
    mutate?: KeyedMutator<Campaign>
}

const MobilePostListRow = ({ post, editable, mutate }: MobilePostListRowProps) => {
  const [expanded, setExpanded] = useState(false)

  const handleSetExpanded = () => {
    if (expanded) {
        setExpanded(false)
    }
    else {
        setExpanded(true)
    }
  }

  const removePost = async (id: number) => {
    if (confirm("Are you sure you want to delete this post?")) {
      await deletePost(id);
      mutate();
    }
  };

  return ( 
    <Flex direction="row" justify="between" align={expanded ? "start":"center"}>
        <Flex direction="row" justify="start" gap="5">
        <Flex direction="row" align="start" gap={"1"}>
            <Link href={post.url} target="_blank">
                <div className="small-thumbnail" style={{ position: "relative", cursor: "pointer" }}>
                    {post.thumbnailSmall ? <Image
                        fill
                        className="small-thumbnail"
                        src={`https://wvemedia.com/image-upload/${post.thumbnailSmall}`}
                        alt=""
                    /> :
                        post.thumbnail ? <Image
                            fill
                            className="small-thumbnail"
                            src={`https://wvemedia.com/image-upload/${post.thumbnail}`}
                            alt=""
                        /> : <Image
                            fill
                            className="small-thumbnail"
                            src="/PostImage.png"
                            alt=""
                        />}
                </div>
            </Link>
        </Flex>
        <Flex direction="column" gap="2" align="start" justify="center">
        {post.username && 
        <Flex direction="row">
            {post?.missing && 
                <IconAlertTriangle className="icon-accent" size="20" />
                }
            <Text size="1" className="font-semibold">@{post.username}</Text>
        </Flex>}
        {expanded ? 
            <Flex direction="column" gap="3"> 
            <Flex direction="row" gap="3">
                <Flex direction="column" gap="2">
                    <Text size="1">Post Date</Text>
                    <Text size="1">Likes</Text>
                    <Text size="1">Shares</Text> 
                    <Text size="1">Views</Text>
                    <Text size="1">Comments</Text>
                </Flex>
                <Flex direction="column" gap="2">
                    <Text size="1" className="font-semibold">{new Date(post.postedAt)?.toLocaleDateString()}</Text>
                    <Text size="1" className="font-semibold">{post.likes?.toLocaleString() || "N/A"}</Text> 
                    <Text size="1" className="font-semibold">{post.shares?.toLocaleString() || "N/A"}</Text>
                    <Text size="1" className="font-semibold">{post.views?.toLocaleString() || "N/A"}</Text>
                    <Text size="1" className="font-semibold">{post.comments?.toLocaleString() || "N/A"}</Text>
                </Flex>
            </Flex>
            {editable && 
                <Flex direction="row" align="center" gap="3">
                    <Link href={`/post/${post.id}`}>
                        <Button variant="outline">History</Button>
                    </Link>
                    { post.tags && <NewEditPost mutate={mutate} post={post} />}
                    <IconButton variant="outline" onClick={() => removePost(post.id)}>
                        <IconTrash className="icon-accent" />
                    </IconButton>
                </Flex>}
            </Flex> : 
            <Flex direction="column" gap="2">
            <Flex direction="row" justify="between" align="end" gap="2">
                {post.views && (
                <Flex direction="row" gap="1" justify="start" align="center"> 
                    <IconPlayerPlay className="icon" width="12px" height="12px"/>
                    <Text size="1">{formatNumber(post.views)}</Text>
                </Flex>
                )}
                {post.likes && (
                <Flex direction="row" gap="1" justify="start" align="center"> 
                    <IconHeart className="icon" width="12px" height="12px" />
                    <Text size="1">{formatNumber(post.likes)}</Text>
                </Flex>
                )}
                {post.comments && (
                <Flex direction="row" gap="1" justify="start" align="center"> 
                    <IconMessage className="icon" width="12px" height="12px" />
                    <Text size="1">{formatNumber(post.comments)}</Text>
                </Flex>
                )}
            </Flex>
        </Flex>
        }
        </Flex>
        </Flex>
        <Flex>
            <IconButton variant="ghost" onClick={handleSetExpanded}>
                <IconChevronDown className="icon-accent"/>
            </IconButton>
        </Flex>
    </Flex>
  )
}

export default MobilePostListRow