import useUser from "@/lib/useUser";
import { aggregate, formatNumber } from "@/lib/util";
import { useEffect, useState } from "react";
import fetchJson from "@/lib/fetchJson";
import useSWR from "swr";
import { chartValues } from "./calc";
import { useCampaign } from "@/services/campaign";
import { Box, Flex, Heading, SegmentedControl, Text } from "@radix-ui/themes";
import NewCampaignProgress from "./NewCampaignProgress";
import NewMultiSelect from "../../CustomUiComponents/NewMultiSelect";
import MobilePostsGridView from "../Posts/MobilePostsGridView";
import MobilePostsListView from "../Posts/MobilePostsListView";
import Playlists from "../Playlists";
import { IconBrandSpotify, IconLayoutGrid, IconList } from "@tabler/icons-react";
import Image from "next/image";
import ApexGraph from "./ApexGraph";
import DateFilter from "@/components/CustomUiComponents/DateFilter";


interface DashboardProps {
    id: string;
    setId: (id: string) => void;
  }

export const MobileTotals = ({
    title,
    value,
  }: {
    title: string;
    value: string;
  }) => (
    <Flex direction="column" gap="2" width="50%">
        <Text size="3">{title}</Text>
        <Heading size="8">{value}</Heading>
    </Flex>);
    

function distributeValues(data: any[]) {
    const result: { [key: string]: number } = {};
    const today = new Date().toISOString().split("T")[0]
    const prng = new PRNG(1234); // Initialize PRNG with a fixed seed
  
    data.forEach((item) => {
      const startDate = new Date(item.createdAt);
      const endDate = new Date(today);
      const value = parseInt(item.value, 10);
  
      const days = Math.floor(
        (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24),
      );
      const dailyBaseValue = value / days;
  
      for (let i = 0; i <= days; i++) {
        const currentDate = new Date(startDate);
        currentDate.setDate(currentDate.getDate() + i);
        const dateStr = currentDate.toISOString().split("T")[0]
  
        if (!result[dateStr]) {
          result[dateStr] = 0;
        }
  
        // Introduce a small random adjustment
        const randomAdjustment = (prng.next() - 0.5) * dailyBaseValue * 0.2; // Adjust this factor as needed
        result[dateStr] += dailyBaseValue + randomAdjustment;
      }
    });
  
    return Object.keys(result)
      .map((date) => [
        date.replace(/\//g, "-"),
        parseFloat(result[date].toFixed(2)),
      ])
      .sort((a, b) => a[0].toString().localeCompare(b[0].toString()));
  }
  
  class PRNG {
    private seed: number;
  
    constructor(seed: number) {
      this.seed = seed;
    }
  
    next(): number {
      const x = Math.sin(this.seed++) * 10000;
      return x - Math.floor(x);
    }
  }
  
  const getPlaylists = (data: Campaign) => {
    if (!data) return [];
    const sp = JSON.parse(data?.song?.playlists || "{}")["1year"];
    if (!sp) return [];
    const playlists = data?.post?.filter(
      ({ type }) => type === "spotify-playlist",
    );
    const tmp = playlists?.map((item) => {
      const id = item.url.split("/")[4];
      const val = sp.find((i: any) => `spotify:playlist:${id}` === i.uri);
      return { id, value: val?.streams, createdAt: val?.dateAdded };
    });
  
    return distributeValues(tmp || []);
  };
  type ValuePiece = Date | null;

  type Value = ValuePiece | [ValuePiece, ValuePiece];
  const MobileDashboardView = ({ id }: DashboardProps) => {
    const { } = useUser();
    const [postsView, setPostsView] = useState("grid");
    const { data } = useCampaign(id);
    const { data: ugc } = useSWR(`/campaign/${id}/ugc`, fetchJson);
    const { data: streams } = useSWR(`/campaign/${id}/streams`, fetchJson);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [filterDates, setFilterDates] = useState<Value>([null, null]);
    const ALL_TYPES = [
      "Tiktok",
      "Instagram",
      "Twitter",
      "Youtube",
    ]
    const [types, setTypes] = useState<string[]>(ALL_TYPES);
    const ALL_STATS = [
      "Followers",
      "Streams",
      "Playlist Adds",
      "UGC Videos",
    ]
    const [statsShown, setStatsShown] = useState<string[]>(ALL_STATS)
  
    const playlists = data?.post?.filter(
      ({ type }) => type === "spotify-playlist",
    );
    const longPlaylists = getPlaylists(data as any);
    const [hasSpotifyData, setHasSpotifyData] = useState(false)
    
      useEffect(() => {
        const spotify = JSON.parse(data?.song?.spotifyData || "{}")
        const hasData = JSON.parse((data?.artist as any)?.followers || "{}")?.followers?.current_period_timeseries?.length > 0 || spotify?.playlist_adds?.current_period_timeseries?.length > 0 || spotify?.streams?.current_period_timeseries?.length > 0
        setHasSpotifyData(hasData)
        if (!hasData) {
          setStatsShown(["UGC Videos"])
        } else {
          setStatsShown(ALL_STATS)
        }
      }, [data])
    console.log("hasSpotifyData", data, ugc, streams, playlists, hasSpotifyData);
  
    const calcPostStat = (posts: any, key: string) => {
        if (!Array.isArray(posts)) return [];
        const ugcTotal = posts?.map((item: any) =>
          (item.postStat as any[])
            .filter((i: any) => i[key])
            .map((i: any) => ({
              date: `${i.createdAt}`.split("T")[0],
              posts: i[key],
            }))
            .reduce((acc, curr) => {
              acc[curr.date] = curr.posts;
              return acc;
            }, {} as any),
        );
        const keys = new Set(
          ugcTotal?.map((item: any) => Object.keys(item)).flat(),
        );
        const kk = Array.from(keys);
        if (key === "views") {
          const result = kk.map((key: any) => [
            key,
            ugcTotal
              ?.map((item: any) => item[key] || 0)
              .reduce((acc: any, curr: any) => acc + curr, 0),
          ]);
          console.log("resss", result);
          return result;
        }
        // const vals = kk
        //   ?.map((key: any) => [
        //     key,
        //     ugcTotal
        //       ?.map((item: any) => item[key] || 0)
        //       ?.reduce(
        //         (acc: any, curr: any) =>
        //           key === "views" ? acc + curr : Math.max(acc, curr),
        //         key === "posts" ? -(data?.post.length || 0) : 0,
        //       ),
        //   ])
        //   .sort();
  
      kk?.sort((a, b) => (new Date(a) as any) - (new Date(b) as any))
      const tracker = {}
      let vals = kk?.map((date) => {
        let amount = 0;
        for (let i = 0; i < ugcTotal.length; i++) {
          const item = ugcTotal[i]
          if (tracker[i] === undefined) {
            tracker[i] = 0
          }
          let currAmount = Math.max(item[date] || 0, tracker[i])
          
          amount += currAmount
          tracker[i] = currAmount
        }
        return [date, amount]

      }).sort((a: any, b: any) => new Date(a[0]).getTime() - new Date(b[0]).getTime());
    
        return vals;
      
    };
  
    const vals = calcPostStat(ugc, "posts");
    const vals2 = calcPostStat(streams, "views");
    console.log("vals", vals, vals2);
  
    const values = chartValues(
      data?.artist,
      JSON.parse(data?.song?.spotifyData || "{}"),
      vals?.map((item) => Math.max(item[1], 0)) || [],
    );
    const ugcVals =
      vals?.map((item) => ({
        x: item[0],
        y: Math.max(item[1], 0),
      })) || [];

  
    const streamVals =
      vals2?.map((item) => ({
        x: item[0],
        y: Math.max(item[1], 0),
      })) || [];
  
    let posts = data?.post
      ?.filter(({ type }) =>
        ["instagram", "tiktok", "twitter", "youtube"].includes(type),
      )
      .filter(
        (post) =>
          !selectedTags.length ||
          post.tags.some((tag) => selectedTags.includes(tag)),
      )
      .filter((post) => types.map((i) => i.toLowerCase()).includes(post.type))
      .filter((post) => {
        const postedAt = new Date(post.postedAt)
        let valid = true
        if (filterDates === null) {
          return true;
        }
        if (filterDates instanceof Date) {
          return postedAt > filterDates
        }
        if (filterDates[0] !== null && postedAt <= filterDates[0]) {
            valid = false;
        }
        if (filterDates[1] !== null && postedAt >= filterDates[1]) {
            valid = false;
        }
        return valid
      });
    return (
      <Flex direction="column" gap="4" py="5">
        <Flex className="box-mobile" py="4" px="7" gap="2" direction="row" justify="between" align="center">
          <Flex direction="row" gap="3" align="center">
            <Flex width="50px" height="50px" className="profile-background">
              <Image  
                  src={`https://wvemedia.com/image-upload/${data?.artist?.image}`}
                  alt=""
                  height={50}
                  width={50}
                  style={{borderRadius: "100%"}}
              />
              </Flex>
              <Heading size="5">{data?.artist?.name}</Heading>
            </Flex>
              <IconBrandSpotify className="icon" width="30px" height="30px" onClick={() => {
          window.open(`https://open.spotify.com/artist/${data?.artist?.spotifyId}`, "blank")
        }}/>
        </Flex>
            <Flex className="box-mobile" direction="column" p="5" gap="3">
              <Heading size="4">Post Analytics</Heading>
              <Flex direction="row" gap="3" pb="2">
              <DateFilter setFilterDates={setFilterDates} filterDates={filterDates} />
                <NewMultiSelect transparent style={{width: 130, marginRight: 10}} setSelected={setSelectedTags} title="Tags" selected={selectedTags} options={Array.from(new Set(data?.tags)).toSorted() as any}/>
                <NewMultiSelect transparent style={{width: 140, marginRight: 10}} setSelected={setTypes} title="Platform" selected={types} options={ALL_TYPES}/>
              </Flex>
              <Flex direction="row" gap="2" justify="between">
                  <MobileTotals
                      title="Views"
                      value={formatNumber(aggregate(posts, "views"))}
                      />
                  <MobileTotals
                      title="Likes"
                      value={formatNumber(aggregate(posts, "likes"))}
                      />
                </Flex>
                <Flex direction="row" gap="2" justify="between">
                    <MobileTotals
                        title="Shares"
                        value={formatNumber(aggregate(posts, "shares"))}
                        />
                    <MobileTotals
                        title="Comments"
                        value={formatNumber(aggregate(posts, "comments"))}
                        />
                </Flex>
            </Flex>
            <Flex direction="column" gap="4">
                <Flex className="box-mobile" direction="column" pl="5" pt="5" pr="5" gap="5" >
                    <Flex direction="row" justify="between" align="center">
                        <Heading size="4">Activity</Heading>
                        <Flex direction="row" gap="3">
                          <NewMultiSelect transparent key={`${hasSpotifyData}`} style={{width: 150, marginRight: 10}} setSelected={setStatsShown} title="Select Metrics" selected={statsShown} options={hasSpotifyData ? ALL_STATS : ["UGC Videos"]}/>
                        </Flex>
                    </Flex>
                    <Box width="100%" height="100%">
                        <ApexGraph 
                        spotify={JSON.parse(data?.song?.spotifyData || "{}")}
                        ugc={ugcVals}
                        artist={data?.artist}
                        streams={streamVals}
                        loading={!data}
                        longPlaylists={longPlaylists}
                        shown={statsShown} />
                    </Box>
                </Flex>
                <Flex className="box-mobile">
                    {data && <NewCampaignProgress campaign={data} spotifyPlaylists={JSON.parse(data?.song?.playlists || "{}")}/>}
                </Flex>
            </Flex>
            <Flex className="box-mobile" gap="5" direction="column" p="5">
                <Flex direction="row" justify="between" align="center">
                    <Heading size="4">Posts</Heading>
                    <SegmentedControl.Root defaultValue="grid">
                        <SegmentedControl.Item value="grid" onClick={()=>(setPostsView("grid"))}>
                          <IconLayoutGrid className="icon"/>
                        </SegmentedControl.Item>
                        <SegmentedControl.Item value="list" onClick={()=>(setPostsView("list"))}>
                          <IconList className="icon"/>
                        </SegmentedControl.Item>
                    </SegmentedControl.Root>
                </Flex>
                {postsView === "grid" ? 
                <Flex direction="column">
                  <MobilePostsGridView fullPosts={posts || []} /> 
                </Flex> :
                <Flex direction="column" p="3">
                    <MobilePostsListView editable={false} fullPosts={posts || []}/>
                </Flex>}
              </Flex>
              <Flex>
              <Flex className="box-mobile" p="5" width="100%">
                <Playlists 
                  fullPlaylists={playlists}
                  spotifyPlaylists={JSON.parse(data?.song?.playlists || "{}")}
                  style="mobile"/>
              </Flex>
              </Flex>
        </Flex>    
    )
}
export default MobileDashboardView;
