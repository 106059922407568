import fetchJson from "@/lib/fetchJson";
import useSWR from "swr";

export const useArtists = () => useSWR<Artist[]>(`/artist/`, fetchJson);

export const useSongs = (artistId: number | undefined) =>
  useSWR<Artist>(`/artist/${artistId}`, fetchJson);

export const deleteSong = async (id: number) =>
  fetchJson(`/artist/song/${id}`, {
    method: "DELETE",
  });

export const deleteArtist = async (id: number) =>
  fetchJson(`/artist/${id}`, {
    method: "DELETE",
  });

export const createArtist = async (name: string, spotifyLink: any) =>
  fetchJson(`/artist/`, {
    method: "POST",
    body: JSON.stringify({ 
      name, 
      spotifyId: spotifyLink?.value?.id,
      spotifyLoginId: spotifyLink?.value?.spotifyId,
    }),
  });

export const createSong = async (id: number, name: string) =>
  fetchJson(`/artist/song/${id}`, {
    method: "POST",
    body: JSON.stringify({ name }),
  });
