import { useState } from "react";
import PostsRow from "./PostsRow";
import { Button, Flex } from "@radix-ui/themes";
import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";

interface Props {
  fullPosts: any;
}

const PostsGridView = ({
  fullPosts,
}: Props) => {
  const [firstRow, setFirstRow] = useState<any>([0, 5])
  const posts = fullPosts.sort((a: any, b: any) => b.views - a.views).filter((post: any) => !post.missing && (post.thumbnail || post.thumbnailSmall));
  console.log({firstRow})
  return (
    <Flex direction="column" gap="4">
      <PostsRow fullPosts={posts} postsRange={firstRow}/>
      <PostsRow fullPosts={posts} postsRange={[firstRow[0] + 5, firstRow[1] + 5]}/>
      <Flex direction="row" gap="3" justify="center">
        <Button variant="ghost" disabled={firstRow[0] === 0} onClick={() => setFirstRow((prev: any) => [Math.max(0, prev[0] - 10), Math.max(5, prev[1] - 10)]) }><ChevronLeftIcon/>Previous</Button>
        <Button variant="ghost" disabled={firstRow[0] >= posts.length - 10} onClick={() => setFirstRow((prev: any) => [Math.min((Math.ceil(posts.length / 10) * 10) - 10, prev[0] + 10), Math.min(Math.ceil(posts.length / 10) * 10, prev[1] + 10)]) }>Next<ChevronRightIcon/></Button>
      </Flex>
    </Flex>
  )}

export default PostsGridView;
